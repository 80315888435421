import StripePayment from './StripePayment'
import PaypalPayment from './PaypalPayment'
import { useCallback, useEffect, useState } from 'react'
import RadioButton from '../common/blocks/RadioButton'
import { useTranslation } from 'react-i18next'
import apiCheckout from '../../api/checkout'

const Payment = (props) => {
  const { t } = useTranslation()

  const [type, setType] = useState('stripe')
  const [paymentData, setPaymentData] = useState<DomainWithPayment | undefined>(undefined)

  const getPaymentData = useCallback(async () => {
    const paymentData = (await apiCheckout.getAll(`/get-domain-payment`, {})) as any

    if (paymentData?.id) {
      const data: DomainWithPayment = paymentData
      setPaymentData(data)
    }
  }, [paymentData, setPaymentData])

  useEffect(() => {
    getPaymentData()
  }, [])

  return (
    <div className="payment__method--grid">
      <div className="elem__payment--method" onClick={() => setType('stripe')}>
        <p>
          <span>
            <img src="/images/paym1.svg" alt="paym" />
          </span>{' '}
          {t('Card')}
        </p>
        <div className="form-group">
          <div className="row-group">
            <div className="row-group__item">
              <RadioButton
                changeValue={() => setType('stripe')}
                selected={type === 'stripe' ? 1 : 0}
                name="payment_type"
                value={1}
              />
            </div>
          </div>
        </div>
      </div>
      {type === 'stripe' && <StripePayment {...props} paymentData={paymentData} />}
      <div className="elem__payment--method" onClick={() => setType('paypal')}>
        <p>
          <span>
            <img src="/images/paym2.svg" alt="paym" />
          </span>{' '}
          {t('Paypal')}
        </p>
        <div className="form-group">
          <div className="row-group">
            <div className="row-group__item">
              <RadioButton
                changeValue={() => setType('paypal')}
                selected={type === 'paypal' ? 1 : 0}
                name="payment_type"
                value={1}
              />
            </div>
          </div>
        </div>
      </div>
      {type === 'paypal' && <PaypalPayment {...props} paymentData={paymentData} />}
    </div>
  )
}

export type DomainWithPayment = {
  id: number
  PaymentAccount: {
    id: number
    stripePublicKey: string
    paypalClientId: string
  }
}

export default Payment
