import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { DomainWithPayment } from '.'
import checkRequired from '../../functions/isRequired'
import { RootState } from '../../redux-store/reducers'
import FormLoading from '../common/blocks/FormLoading'

export default function PaypalPayment({
  placeOrder,
  amount,
  loading,
  data,
  shippingRequired,
  ...props
}) {
  const paymentData: DomainWithPayment = props.paymentData
  const currency = useSelector((state: RootState) => state.init.currency)

  const { t } = useTranslation()

  const [valid, setValid] = useState(false)
  useEffect(() => {
    setValid(validate())
  }, [data])

  const submitForm = useCallback(
    (e) => {
      e.preventDefault()
      if (!valid) {
        toast.error(t('Fill all shipping information!'))
      } else {
        toast.info(t('Click PayPal button to proceed!'))
      }
    },
    [data, valid]
  )

  const validate = useCallback(() => {
    if (shippingRequired) {
      const { isValid } = checkRequired(data, ['recipient', 'phone', 'country', 'address'], t, true)
      return isValid
    }
    return true
  }, [data])

  if (loading) {
    return <FormLoading loadingText={t('Loading...')} />
  }
  if (!valid) {
    return (
      <div className="elem__payment--method">
        <button className="pointer">{t('Fill all shipping information!')}</button>
      </div>
    )
  }

  return (
    <form id={'payment-form'} onSubmit={submitForm}>
      <PayPalScriptProvider
        options={{
          currency: currency.name,
          'client-id':
            paymentData.PaymentAccount.paypalClientId ??
            process.env.REACT_APP_PAYPAL_CLIENTID ??
            '',
        }}
      >
        <PayPalButtons
          fundingSource={'paypal'}
          style={{ layout: 'horizontal' }}
          createOrder={(_data, actions) => {
            return actions.order
              .create({
                purchase_units: [
                  {
                    amount: {
                      currency_code: currency.name,
                      value: amount,
                    },
                  },
                ],
              })
              .then((orderId) => {
                // Your code here after create the order
                return orderId
              })
          }}
          onApprove={function (data, actions: any) {
            return actions.order.capture().then(function () {
              placeOrder({
                noPayment: false,
                emailChanged: false,
                elements: false,
                stripe: false,
                paypal: data,
              })
              // Your code here after capture the order
            })
          }}
          onError={function (err: any) {
            toast.error(err.message)
          }}
        />
      </PayPalScriptProvider>
    </form>
  )
}
